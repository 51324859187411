import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appName, baseUrl } from '../../../config/config';
import HeaderMenu from './HeaderMenu';
import CustomImage from '../image/CustomImage';
import { faBars, faLocationArrow, faPhoneAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

const Header = ({ auth, siteSetting }) => {

    const [menuActive, setMenuActive] = useState(false);

   

    const [fixedMenu, setFixedMenu] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', checkScrollTop);
        return () => window.removeEventListener('scroll', checkScrollTop);
    }, []);

    const checkScrollTop = () => {
        if (window.pageYOffset > 200) {
            setFixedMenu(true);
        } else if (window.pageYOffset <= 200) {
            setFixedMenu(false);
        }
    };

    const handleMenuButton = () => {
        setMenuActive(true);
    };
    const handleMenuClose = () => {
        setMenuActive(false);
    };


    return (<>

        <section className="main-menu-area mobile d-xl-none">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-2 col-sm-2">
                        <div className="menu-bar-button"><i className="fas fa-bars"></i></div>
                    </div>
                    <div className="col-8 col-sm-8 text-center">
                        <div className="logo">
                            <Link href="/">
                                <a>
                                    <img src={baseUrl + "/images/logo.png"} alt="Tech Excellence" />
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="col-2 col-sm-2 text-end">
                        <div className="menu-call-button">
                            <a href="tel:+880123456789" className="gradient-button bg-gradient-blue boxed-shadow-blue">
                                <FontAwesomeIcon icon={faPhoneAlt} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div className="drawer-menu-area d-xl-none">
            <div className="drawer-menu-backdrop"></div>
            <div className="drawer-menu">
                <h3>
                    <span>Menu</span>
                    <span className="drawer-menu-close">
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                </h3>
                <div className="mobile-main-menu">
                    <ul>
                        <li><a href="#home">Home</a></li>
                        <li><a href="#services">Services</a></li>
                        <li><a href="#products">Products</a></li>
                        <li><a href="#review">Reviews</a></li>
                        <li><a href="#blog">Blog</a></li>
                        <li><a href="#">Career</a></li>
                        <li><a href="#contact">Contact us</a></li>
                        <li><a href="#make-a-proposal">make a proposal</a></li>
                    </ul>
                </div>
            </div>
        </div>



        <section className={`main-menu-area d-none d-xl-block ${fixedMenu ? 'fixed' : ''}`}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-2">
                        <div className="logo">
                            <Link href="/">
                                <a>
                                    <img src={baseUrl + "/images/logo.png"} alt="Tech Excellence" />
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-10 text-end">
                        <div className="main-menu">
                            <ul>
                                <li><a href="#home">Home</a></li>
                                <li><a href="#services">Services</a></li>
                                <li><a href="#products">Products</a></li>
                                <li><a href="#review">Reviews</a></li>
                                <li><a href="#blog">Blog</a></li>
                                <li><a href="#">Career</a></li>
                                <li><a href="#contact">Contact us</a></li>
                                <li><a href="#make-a-proposal">make a proposal</a></li>
                            </ul>
                        </div>
                        <div className="menu-call-button">
                            <a href="tel:+880123456789" className="gradient-button bg-gradient-blue boxed-shadow-blue"><i className="fas fa-phone-alt"></i>  +880123456789</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>);
};


const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};


export default connect(mapStateToProps, null)(Header);