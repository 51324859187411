export const apiUrl = 'https://api.rafiulislam.com';
// export  const apiUrl = 'http://localhost:9000';

// export const baseUrl = "http://localhost:3000";
export const baseUrl = "http://techexcellence.mri29.com/";

export const GA_TRACKING_ID = 'UA-158759976-122';
export const appName = "Tech Excellence";
export const appWebsite = "http://techexcellence.mri29.com/";
export const devName = "Md. Rafiul Islam";
export const devWebsite = "https://rafiulislam.com/";
export const classPrefix = "mri";
export const googleMapApiKey = "AIzaSyDBe0SDmy3dhz8abKvImFqvif8fAVHYts8";
export const googleMapDefaultLocation = { 
    lat: 23.7808875, 
    lng: 90.2792371
};
export const googleMapCountryList = ['bd']
export const googleMapDefaultZoom = 14;
export const fbID = "359433559301511";
export const fbPageID = "105751340935336";

export const siteTitle = 'Tech Excellence';
export const siteTagLine = 'Corporate Website';
export const metaSiteTitle = siteTagLine + " | " + siteTitle;
export const metaDesc = "Tech Excellence";
export const metaKeywords = "Tech Excellence, BPO, Software Development, Web Development, Frontend Development, Backend Development, Corporate website, Bangladesh, Outsourcing, development, PHP, Laravel, Node Js, React Js, Next Js, Python";